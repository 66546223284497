import StripeSubscription from "./Subscription";

const RegisterPage = () => {
  return (
    <>
      <div className="dashboard">
        <div className="welcome checkout">
          <StripeSubscription />
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
