import React, { useEffect, useState } from "react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, db } from "../Firebase/firebaseClient";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import googleLogo from "../Google__G__logo.svg";
import AllRoutes from "./Routes";
import LandingPage from "./LandingPage";
import Socials from "./Socials";
import NavBarLandingPage from "./NavBarLandingPage";
import HowItWorks from "./HowItWorks";
import PricingPage from "./PricingPage";
import AboutUs from "./AboutUs";
import Footer from "./Footer";
import TermsAndConditions from "./terms ans service";
import Login from "./LoginSignUpPage";
import TipsForAgents from "./Blog Pages/TipsForAgents";

const FirebaseAuth = (response) => {
  const [user, setUser] = useState();
  const [userDoc, setUserDoc] = useState();

  const SignInWithGoogle = async (response) => {
    const provider = new GoogleAuthProvider();
    const { user } = await signInWithPopup(auth, provider);

    const userDocRef = doc(db, "newUsers", user.uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if (!userDocSnapshot.exists()) {
      await setDoc(userDocRef, {
        uid: user.uid,
        name: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
      });
    }

    // Add user to 'customers' collection
    const customerDocRef = doc(db, "customers", user.uid);
    const customerDocSnapshot = await getDoc(customerDocRef);

    if (!customerDocSnapshot.exists()) {
      try {
        await setDoc(customerDocRef, {
          uid: user.uid,
          name: user.displayName,
          
        });
        console.log("Customer added to Firestore");
      } catch (error) {
        console.error("Error adding customer to Firestore:", error);
      }
    }

    setUserDoc(user);
  };

  const Logout = () => {
    auth.signOut();
    setUser(null);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const firstName = user.displayName.split(" ")[0];
        setUser(firstName);
      }
    });

    return unsubscribe;
  }, []);

  return (
    <>
      <div className="banner">
        {user && (
          <div className="userProfile">
            <div>
              <h3 className="userName">{user}</h3>
            </div>
            <button className="logOut" onClick={Logout}>
              Logout
            </button>
          </div>
        )}
      </div>
      {!user && (
        <div>
          <NavBarLandingPage />
          <BrowserRouter>
            <div>
              <Routes>
                <Route path="/" element={<LandingPage SignInWithGoogle={SignInWithGoogle} />} />
                <Route path="/how-it-works" element={<HowItWorks />} />
                <Route path="/pricing-plans" element={<PricingPage />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/user-login-signup-page" element={<Login SignInWithGoogle={SignInWithGoogle} />} />
                <Route path="/tips-for-agents" element={<TipsForAgents/>} />
              </Routes>
            </div>
          </BrowserRouter>
          <div id="signIn">
            <div className="google-btn">
              <button className="google-button" onClick={SignInWithGoogle}>
                <img src={googleLogo} alt="Google" className="button-icon" />
                Login with Google
              </button>
            </div>
          </div>
          <br /><br />
          <Socials />
          <br />
          <Footer />
        </div>
      )}
      {user && <AllRoutes user={user} userDoc={userDoc} />}
    </>
  );
};

export default FirebaseAuth;
