import React, { useEffect, useState } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from "../Firebase/firebaseClient";

const SavedContent = () => {
  const [favs, setFavs] = useState([]);
  const [editedIndex, setEditedIndex] = useState(-1); // Track the index of the currently edited content
  const [editedContent, setEditedContent] = useState('');

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(db, 'newUsers', user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            const favorites = userData.favorites || [];
           
            setFavs(favorites);
            console.log(favorites)
          }
        }
      } catch (error) {
        console.error('Error fetching favorites:', error);
      }
    };

    fetchFavorites();
  }, []);

  const handleEdit = (index) => {
    setEditedIndex(index);
    setEditedContent(favs[index]);
  };

  const handleSaveEdit = async (index) => {
    try {
      // Update the content in the favorites array
      const updatedFavorites = [...favs];
      updatedFavorites[index] = editedContent;

      // Update the favorites array in Firestore
      const user = auth.currentUser;
      const userDocRef = doc(db, 'newUsers', user.uid);
      await updateDoc(userDocRef, { favorites: updatedFavorites });

      setFavs(updatedFavorites);
      setEditedIndex(-1);
    } catch (error) {
      console.error('Error saving edited content:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditedIndex(-1);
    setEditedContent('');
  };

  const emailContent = () => {
    const subject = encodeURIComponent('My Ad Text');
    const body = encodeURIComponent(editedContent);
    const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
  
    window.open(mailtoLink, '_blank');
  };

  const handleDelete = async (index) => {
    try {
      const updatedFavorites = favs.filter((_, i) => i !== index); // Remove the content at the specified index

      // Update the favorites array in Firestore
      const user = auth.currentUser;
      const userDocRef = doc(db, 'newUsers', user.uid);
      await updateDoc(userDocRef, { favorites: updatedFavorites });

      setFavs(updatedFavorites);
    } catch (error) {
      console.error('Error deleting content:', error);
    }
  };

  return (
    <div className='savedContent'>
    {favs.length === 0 ? (
      <div className='responseBox'>You Have No Saved Ads</div>
    ) : (
      favs.map((favorite, index) => (
        <div className="savedAds" key={index}>
          {editedIndex === index ? (
            <div>
              <textarea
                className='responseBox edit' 
                type='text'
                value={editedContent}
                onChange={(e) => setEditedContent(e.target.value)}
              />
              <button className='crudButton' onClick={() => handleSaveEdit(index)}>Save</button>
              <button className='crudButton' onClick={handleCancelEdit}>Cancel</button>
            </div>
          ) : (
            <div>
              {favorite}
              <div className='crudButtons'>
                <button className='crudButton' onClick={() => handleEdit(index)}>Edit</button>
                <button className='crudButton' onClick={() => handleDelete(index)}>Delete</button>
                <button className='crudButton' onClick={() => emailContent(index)}>Email</button>
              </div>
            </div>
          )}
        </div>
      ))
    )}
  </div>
  );
  
};
export default SavedContent;
