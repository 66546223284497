import React from "react";


const Footer = () => {
  return (
    <>
    <br />
      <div className="footer">
        <div id="button"></div>
        
        <div id="container">
    
          <div id="cont">
            <div className="footer_center">
              <h3>&copy;ADTEXT 2024</h3>
             <a href="/terms-and-conditions"><h3>Terms & Conditions</h3></a> 
            </div>
           
          </div>
         
        </div>
      </div>
    </>
  );
};

export default Footer;

