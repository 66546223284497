import React from 'react';

const TermsAndConditions = () => {
    return (
        <>
            <div style={{ background: "url('') repeat-y center top/contain #fff" }} >
                <div className="header">
                    <span style={{ lineHeight: "18.0pt" }} className="content">Website Terms and Conditions</span>
                    <span style={{ lineHeight: "18.0pt" }} className="pageNumbers">Page <span className="currentPageNum"></span> of <span className="totalPageNum"></span></span>
                </div>
                <div className="welcome"></div>
                <div className="firstHeader"></div>
                <div className="firstFooter">
                    <span style={{ lineHeight: "18.0pt" }} className="pageNumbers">Page <span className="currentPageNum"></span> of <span className="totalPageNum"></span></span>
                </div>
                <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "center" }}>
                    <span style={{ fontStyle: "normal", fontWeight: "bold", textDecoration: "underline" }}>TERMS AND CONDITIONS</span>
                </p>
                <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                    These terms and conditions (the "Terms and Conditions") govern the use of <span style={{ fontStyle: "normal", fontWeight: "bold" }}>https://www.adtextai.com/</span> (the "Site"). This Site is owned and operated by ADTEXT LTD. This Site is an online service.<br /><br />By using this Site, you indicate that you have read and understand these Terms and Conditions and agree to abide by them at all times.
                </p>
                <div>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        <span style={{ fontStyle: "normal", fontWeight: "bold", textDecoration: "underline" }}>Intellectual Property</span><br />All content published and made available on our Site is the property of ADTEXT LTD and the Site's creators. This includes, but is not limited to images, text, logos, documents, downloadable files and anything that contributes to the composition of our Site.
                    </p>
                </div>
                <div>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        <span style={{ fontStyle: "normal", fontWeight: "bold", textDecoration: "underline" }}>Age Restrictions</span><br />The minimum age to use our Site is 18 years old. By using this Site, users agree that they are over 18 years old. We do not assume any legal responsibility for false statements about age.
                    </p>
                </div>
                <div>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        <span style={{ fontStyle: "normal", fontWeight: "bold", textDecoration: "underline" }}>Acceptable Use</span><br />As a user of our Site, you agree to use our Site legally, not to use our Site for illegal purposes, and not to:
                    </p>
                    <ul style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", listStyle: "disc" }}>
                        <li style={{ marginBottom: "18.0pt" }} value="1">
                            <span>Harass or mistreat other users of our Site;</span><span style={{ color: "#000000" }}><br /></span>
                        </li>
                        <li style={{ marginBottom: "18.0pt" }} value="2">
                            <span>Violate the intellectual property rights of the Site owners or any third party to the Site;</span><span style={{ color: "#000000" }}><br /></span>
                        </li>
                        <li style={{ marginBottom: "18.0pt" }} value="3">
                            <span>Hack into the account of another user of the Site;</span><span style={{ color: "#000000" }}><br /></span>
                        </li>
                        <li style={{ marginBottom: "18.0pt" }} value="4">
                            <span>Act in any way that could be considered fraudulent; or</span><span style={{ color: "#000000" }}><br /></span>
                        </li>
                        <li style={{ marginBottom: "0.0pt" }} value="5">
                            <span>Post any material that may be deemed inappropriate or offensive.</span><span style={{ color: "#000000" }}><br /></span>
                        </li>
                    </ul>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        If we believe you are using our Site illegally or in a manner that violates these Terms and Conditions, we reserve the right to limit, suspend or terminate your access to our Site. We also reserve the right to take any legal steps necessary to prevent you from accessing our Site.
                    </p>
                </div>
                <div>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        <span style={{ fontStyle: "normal", fontWeight: "bold", textDecoration: "underline" }}>Accounts</span><br />When you create an account on our Site, you agree to the following:
                    </p>
                    <ol start="1" style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", listStyle: "decimal" }}>
                        <li style={{ marginBottom: "18.0pt" }} value="1">
                            <span>You are solely responsible for your account and the security and privacy of your account, including passwords or sensitive information attached to that account; and</span><span style={{ color: "#000000" }}><br /></span>
                        </li>
                        <li style={{ marginBottom: "0.0pt" }} value="2">
                            <span>All personal information you provide to us through your account is up to date, accurate, and truthful and that you will update your personal information if it changes.</span><span style={{ color: "#000000" }}><br /></span>
                        </li>
                    </ol>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        We reserve the right to suspend or terminate your account if you are using our Site illegally or if you violate these Terms and Conditions.
                    </p>
                </div>
                <div>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        <span style={{ fontStyle: "normal", fontWeight: "bold", textDecoration: "underline" }}>Sale of Goods</span><br />These Terms and Conditions govern the sale of goods available on our Site.
                    </p>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        The following goods are available on our Site:
                    </p>
                    <ul style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", listStyle: "disc" }}>
                        <li style={{ marginBottom: "0.0pt" }} value="1">
                            <span>AI Marketing Tools for Real Estate.</span><span style={{ color: "#000000" }}><br /></span>
                        </li>
                    </ul>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        These Terms and Conditions apply to all the goods that are displayed on our Site at the time you access it. This includes all products listed as being out of stock. All information, descriptions, or images that we provide about our goods are as accurate as possible. However, we are not legally bound by such information, descriptions, or images as we cannot guarantee the accuracy of all goods we provide. You agree to purchase goods from our Site at your own risk.
                    </p>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        We reserve the right to modify, reject or cancel your order whenever it becomes necessary. If we cancel your order and have already processed your payment, we will give you a refund equal to the amount you paid. You agree that it is your responsibility to monitor your payment instrument to verify receipt of any refund.
                    </p>
                </div>
                <div>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        <span style={{ fontStyle: "normal", fontWeight: "bold", textDecoration: "underline" }}>Payments</span><br />We accept the following payment methods on our Site:
                    </p>
                    <ul style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", listStyle: "disc" }}>
                        <li style={{ marginBottom: "0.0pt" }} value="1">
                            <span>Credit Card</span><span style={{ color: "#000000" }}><br /></span>
                        </li>
                    </ul>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        When you provide us with your payment information, you authorize our use of and access to the payment instrument you have chosen to use. By providing us with your payment information, you authorize us to charge the amount due to this payment instrument.
                    </p>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        If we believe your payment has violated any law or these Terms and Conditions, we reserve the right to cancel or reverse your transaction.
                    </p>
                </div>
                <div>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        <span style={{ fontStyle: "normal", fontWeight: "bold", textDecoration: "underline" }}>Consumer Protection Law</span><br />Where the Sale of Goods and Supply of Services Act 1980, the Consumer Rights Act 2022, or any other consumer protection legislation in your jurisdiction applies and cannot be excluded, these Terms and Conditions will not limit your legal rights and remedies under that legislation. These Terms and Conditions will be read subject to the mandatory provisions of that legislation. If there is a conflict between these Terms and Conditions and that legislation, the mandatory provisions of the legislation will apply.
                    </p>
                </div>
                <div>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        <span style={{ fontStyle: "normal", fontWeight: "bold", textDecoration: "underline" }}>Limitation of Liability</span><br />ADTEXT LTD and our directors, officers, agents, employees, subsidiaries, and affiliates will not be liable for any actions, claims, losses, damages, liabilities, and expenses including legal fees from your use of the Site.
                    </p>
                </div>
                <div>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        <span style={{ fontStyle: "normal", fontWeight: "bold", textDecoration: "underline" }}>Indemnity</span><br />Except where prohibited by law, by using this Site you indemnify and hold harmless ADTEXT LTD and our directors, officers, agents, employees, subsidiaries, and affiliates from any actions, claims, losses, damages, liabilities, and expenses including legal fees arising out of your use of our Site or your violation of these Terms and Conditions.
                    </p>
                </div>
                <div>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        <span style={{ fontStyle: "normal", fontWeight: "bold", textDecoration: "underline" }}>Applicable Law</span><br />These Terms and Conditions are governed by the laws of the Republic of Ireland.
                    </p>
                </div>
                <div>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        <span style={{ fontStyle: "normal", fontWeight: "bold", textDecoration: "underline" }}>Dispute Resolution</span><br />Subject to any exceptions specified in these Terms and Conditions, if you and ADTEXT LTD are unable to resolve any dispute through informal discussion, then you and ADTEXT LTD agree to submit the issue before an arbitrator. The decision of the arbitrator will be final and binding. Any arbitrator must be a neutral party acceptable to both you and ADTEXT LTD. The costs of any arbitration will be shared equally between you and ADTEXT LTD.
                    </p>
                </div>
                <div>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        Notwithstanding any other provision in these Terms and Conditions, you and ADTEXT LTD agree that you both retain the right to bring an action in small claims court and to bring an action for injunctive relief or intellectual property infringement.
                    </p>
                </div>
                <div>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        <span style={{ fontStyle: "normal", fontWeight: "bold", textDecoration: "underline" }}>Additional Terms</span><br />We may modify these Terms and Conditions from time to time. When we make changes to these Terms and Conditions, we will update the date at the top of this page. Please review these Terms and Conditions regularly to ensure you are aware of any changes.
                    </p>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        If you do not agree to any of these changes, you must immediately stop using our Site.
                    </p>
                </div>
                <div>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        <span style={{ fontStyle: "normal", fontWeight: "bold", textDecoration: "underline" }}>Contact Details</span><br />Please contact us if you have any questions or concerns. Our contact details are as follows:<br />
                        <br />support@adtextai.com<br />
                        <br />You can also contact us through the feedback form available on our Site.
                    </p>
                    <p style={{ lineHeight: "18.0pt", fontSize: "12.0pt", fontFamily: "Times New Roman", color: "#000000", textAlign: "left" }}>
                        <span style={{ fontStyle: "normal", fontWeight: "bold", textDecoration: "underline" }}>Effective Date:</span><br /> 1st day of June, 2023
                    </p>
                </div>
            </div>
        </>
    );
};

export default TermsAndConditions;
