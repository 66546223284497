import "./App.css";
import FirebaseAuth from "./Components/FirebaseAuth";


function App(props) {
  return (
    <>
      <FirebaseAuth />
    </>
  );
}

export default App;
