import React from "react";
import { useState, useEffect } from "react";

const ChatMessage = ({ speed, msg, save, deleteContent, role}) => {
  const Typer = ({ speed = 100, children = " Introduce your text" }) => {
    const [idx, setidx] = useState(0);
    useEffect(() => {
      const timer = window.setInterval(() => setidx((v) => v + 1), speed);
      return () => window.clearInterval(timer);
    });

    return <div  type="input" >{children.substring(0, idx)}</div>;
  };

  return (
    <div className="chatResponseArea">

      <div id="chatBox" className={role}>
        <Typer speed={speed} children={msg}></Typer>
      </div>
    </div>
  );
};

export default ChatMessage;
