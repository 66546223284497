import React, { useEffect, useRef } from "react";
import { useState } from "react";
import ApiMessage from "./APIMessage";
import RandomSalesQuote from "./Loading";
import { collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { auth, db } from "../Firebase/firebaseClient";
import { Link, Outlet, useNavigate } from "react-router-dom";

const ReWrite = () => {
  const [value, setValue] = useState("");
  const [reply, setReply] = useState("");
 const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line
  const [editableReply, setEditableReply] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [autoFocus, setAutoFocus] = useState(false);

  const inputRef = useRef(null);

  const emailContent = () => {
    const subject = encodeURIComponent('My Ad Text');
    const body = encodeURIComponent(reply);
    const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
  
    window.open(mailtoLink, '_blank');
  };


  const GenerateText = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true); 
      const response = await fetch('https://adtextai-usserver.onrender.com/rewrite', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorisation": `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          value,
        }),
      });
      console.log(response.status);
      const data = await response.json();
      setReply(data.content);
      setIsLoading(false); // Set loading to false after receiving the response
    } catch (error) {
      console.error(error);
      setIsLoading(false); // Set loading to false in case of error
    }
  };

  const ReduceTextAction = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const response = await fetch(
        "https://adtextai-usserver.onrender.com/reduce-text",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorisation: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: JSON.stringify({
            reply,
          }),
        }
      );
      console.log(response.status);
      const data = await response.json();
      setReply(data.content);
      setIsLoading(false); // Set loading to false after receiving the response
    } catch (error) {
      console.error(error);
      setIsLoading(false); // Set loading to false in case of error
    }
  };

  const SaveContent = async (user) => {
    try {
      const userDocRef = doc(db, "newUsers", user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userDocData = userDocSnapshot.data();

        if (userDocData.favorites && !userDocData.favorites.includes(reply)) {
          const updatedFavorites = [...userDocData.favorites, reply];
          await updateDoc(userDocRef, { favorites: updatedFavorites });
          alert("Content saved successfully!");
        } else if (!userDocData.favorites) {
          await updateDoc(userDocRef, { favorites: [reply] });
          alert("Content saved successfully!");
        } else {
          alert("Content is already saved.");
        }
      } else {
        await setDoc(userDocRef, { favorites: [reply] });
        alert("Content saved successfully!");
      }
    } catch (error) {
      console.error("Error saving content:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    GenerateText(e);
  };

  const handleClickOutside = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      handleSave();
    }
  };

  const handleSave = () => {
    setEditableReply(reply);
    setIsEditing(false);
    
  };

  const handleEdit = (e) => {
    setReply(e.target.value);
    console.log(reply);
  };

  const [subscription, setSubscription] = useState(null); // State to hold user's subscription
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const fetchSubscription = async () => {
        try {
            const currentUser = auth.currentUser;
            if (currentUser) {
                const userDocRef = doc(db, 'customers', currentUser.uid);
                const subscriptionsCollectionRef = collection(userDocRef, 'subscriptions');
                const activeSubscriptionsQuery = query(subscriptionsCollectionRef, where('status', 'in', ['active', 'trialing']));
                const activeSubscriptionsSnapshot = await getDocs(activeSubscriptionsQuery);

                if (!activeSubscriptionsSnapshot.empty) {
                    setSubscription(true);
                } else {
                    setSubscription(false);
                }
            }
        } catch (error) {
            console.error('Error fetching subscription:', error);
        }
    };

    fetchSubscription();
}, []);

  const handleEditToggle = () => {
    if (subscription) {
      setIsEditing(!isEditing);
      setAutoFocus(true);
    } else {
      setShowPopup(true);
    }
  };
  const navigate = useNavigate();

  const goToSavedAds = () => {
    
    if (subscription) {
      navigate("/user-SavedContent");
      // window.location="/user-SavedContent";
    } else {
      setShowPopup(true);
    }
  }

  const postToSocialMedia = () => {};

  useEffect(() => {
    if (isEditing) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [isEditing]);

  return (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <label>
          <textarea
            className="inputBox inputTextArea"
            type="text"
            value={value}
            placeholder="Paste the ad text you would like to rewrite"
            onChange={(e) => setValue(e.target.value)}
          />
          </label>
      </form>

      <br />
      <br />
      {showPopup && (
        <div className={"popup" + (showPopup ? " visible " : "")}>
          <div className="popup-content">
            <span className="close" onClick={() => setShowPopup(false)}>
              <div className="closeButton">&times;</div>
            </span>
            <p>Premium feature! Get Full Access Now!</p>
            <Link className='createNewButton' to='/register'>Subscribe</Link>
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="loadingData">
          <RandomSalesQuote />
        </div>
      ) : (
        <>
          {isEditing ? (
            <textarea
              className="responseBox edit"
              ref={inputRef}
              type="text"
              value={reply}
              onChange={handleEdit}
              onBlur={handleSave}
              autoFocus={autoFocus}
            />
          ) : (
            <div>
              <ApiMessage
                speed={10}
                reply={reply}
                save={() => SaveContent(auth.currentUser)}
                handleSubmit={handleSubmit}
                emailContent={emailContent}
                handleEditToggle={handleEditToggle}
                isEditing={isEditing ? "Cancel" : "Edit"}
                reduce={ReduceTextAction}
                postToSocialMedia={postToSocialMedia}
                savedAds={goToSavedAds}
              />

              {/* <SocialMedia/> */}
            </div>
          )}
        </>
      )}

      <Outlet />
    </div>
  );
};

export default ReWrite;
