import React from "react";

const AboutUs = () => {
  return (
    <>
      <div className="wavy-border">
        <svg viewBox="0 0 100 100" preserveAspectRatio="none">
          <path
            d="M0 50 Q 25 75, 50 50 T 100 50 L 100 100 L 0 100 Z"
            fill="#1d1e26"
          />
        </svg>
      </div>
      <div className="howItWorks aboutUs">
        <h1 className="mainHeader">About Us</h1>
        <div className=" responseBox ">
          <h1>Welcome to AdText-AI! We're here with a clear purpose: </h1>
          <br />
          <b className="createNewButton">
            To enhance the digital marketing practices of real estate
            professionals.
          </b>
          <br />
          <h1>
            {" "}
            <br />
            Based in New Zealand, we're driven by the belief that quality
            advertising can make a real difference in the real estate world. Our
            focus is on simplicity and utility, providing services that real
            estate agents and offices can genuinely benefit from. Our journey is
            just beginning, but our commitment to improvement is unwavering.
          </h1>
          <br />
          <h1>
            {" "}
            We're here to learn and grow alongside you, offering solutions that
            are both effective and user-friendly. Our team has over a decade of
            combined experience working in marketing and real estate, so we
            understand the unique challenges you face in the industry, and we're
            dedicated to providing tools that cater to your needs. Our goal is
            to make your digital marketing efforts more seamless, leaving you
            with more time to focus on what you do best.{" "}
          </h1>
          <br />
          <h1>
            At AdText, we're not about flashy promises. We're about real results
            and practical support. Join us as we work to elevate the standard of
            real estate digital marketing, one step at a time.
          </h1>
          <br />
        </div>
      </div>
      <div className="wavy-border">
        <svg viewBox="0 0 100 100" preserveAspectRatio="none">
          <path
            d="M0 50 Q 25 25, 50 50 T 100 50 L 100 0 L 0 0 Z"
            fill="#1d1e26"
          />
        </svg>
      </div>
      <br />
    </>
  );
};

export default AboutUs;
