import './Login.css';
import googleLogo from '../Google__G__logo.svg'
import React, { useState } from 'react';

const Login = ({SignInWithGoogle}) => {
  const [isSignUp, setIsSignUp] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const toggleSignUp = () => {
    setIsSignUp(!isSignUp);
  };

  return (
    <>
   
    <div className="login-container">
    
      <div className="login-box">
      <h1 className='mainHeader welcome'>AdText-AI</h1><br className='welcome' />
        {isSignUp ? (
          <>
          
          <br />
            <div className="name-inputs">
              <div className="input-container">
                
                <label className="login-label">First name</label>
                <input type="text" className="login-input" placeholder="Your first name" />
              </div>
              <div className="input-container">
                <label className="login-label">Last name</label>
                <input type="text" className="login-input" placeholder="Your last name" />
              </div>
            </div>
            <label className="login-label">Email</label>
            <input type="email" className="login-input" placeholder="Your email address" />
            <button className="login-button">Send email code</button>
          </>
        ) : (
          <>
         
            {/* <label className="login-label">Email</label>
            <input type="email" className="login-input" placeholder="Your email address" />
            <button className="login-button">Continue</button> */}
          </>
        )}
        <div className="separator">
          <span className="separator-text">Sign up with Google</span>
        </div>
        <button className="google-button" onClick={SignInWithGoogle}>
          <img src={googleLogo} alt="Google" className="button-icon" />
          Continue with Google
        </button>
        {/* <button className="microsoft-button">
          <img src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg" alt="Microsoft" className="button-icon" />
          Continue with Microsoft
        </button> */}
        {/* <p className="signup-text">
          {isSignUp ? 'Already have an account?' : "Don't have an account?"}{' '}
          <button onClick={toggleSignUp} className="signup-link">
            {isSignUp ? 'Sign in' : 'Sign up'}
          </button>
        </p> */}
      </div>
      
    </div>
    </>
  );
};

export default Login;

