import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { auth, db, functions } from "../Firebase/firebaseClient";
import SideBar from "./SideBar";
import { httpsCallable } from "firebase/functions";
import RandomSalesQuote from "./Loading";

const UserProfilePage = () => {
  const [userData, setUserData] = useState(null);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCustomerPortal = async () => {
    setIsLoading(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const createPortalLink = httpsCallable(
          functions,
          "ext-firestore-stripe-payments-createPortalLink"
        );
        const {
          data: { url: portalUrl },
        } = await createPortalLink({ userId: user.uid });

        window.location.assign(portalUrl);
      }
    } catch (error) {
      console.error("Error creating portal link:", error);
      setMessage("Error creating portal link. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(db, "newUsers", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            setUserData(userData);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <>
      <SideBar />
      <div className="dashboard">
        <h2 className="welcome">Your Profile</h2>
        <br />
        <br />
        <br />
        {userData ? (
          <div className="user-details">
            <img className="userPicture" src={userData.photoURL} alt="User" />
            <div className="detail">
              <span className="label">User Name:</span> {userData.name}
            </div>
            <div className="detail">
              <span className="label">Email:</span> {userData.email}
            </div>
            <br />
            {isLoading ? (
              <div className="loadingData">
                <RandomSalesQuote />
              </div>
            ) : (
              <button className="createNewButton" onClick={handleCustomerPortal}>
                Manage Subscription
              </button>
            )}
          </div>
        ) : (
          <p>Loading user data...</p>
        )}
        {message && <p className="errorMessage">{message}</p>}
      </div>
    </>
  );
};

export default UserProfilePage;
