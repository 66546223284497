import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import SideBar from "./SideBar";

const Dashboard = ({ user }) => {
  const [showWelcome, setShowWelcome] = useState(false);

  useEffect(() => {
    setShowWelcome(true);
  }, []);

  return (
    <>
      <SideBar />
      <div className="dashboard">
        <div className={`welcome ${showWelcome ? "show" : ""}`}>
          Welcome to your Dashboard {user}!
        </div>
        <div className="container">

        <Link className="card large" to="/compose-new-ad">
            <h3>Create New Ad</h3>
            <p>Get started! Create a new Ad</p>
          </Link>
          <Link className="card" to="/user-SavedContent">
            <h3>Saved Ads</h3>
            <p>View and manage your ads here</p>
          </Link>

         

          {/* <Link className="card" to="/UserPhotos">
            <h3>Edit A Photo</h3>
            <p>Upload and edit photos here</p>
          </Link> */}
        </div>
      </div>
      *Some features are still under construction
      <Outlet />
    </>
  );
};

export default Dashboard;
