import React from "react";
import { useState, useEffect } from "react";
import { FaEnvelope } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";

const ApiMessage = ({
  speed,
  reply,
  save,
  deleteContent,
  emailContent,
  reduce,
  handleSubmit,
  handleEditToggle,
  isEditing,
  postToSocialMedia,
  savedAds,
}) => {
  const Typer = ({ speed = 100, children = " Introduce your text" }) => {
    const [idx, setidx] = useState(0);
    useEffect(() => {
      const timer = window.setInterval(() => setidx((v) => v + 1), speed);
      return () => window.clearInterval(timer);
    });

    return (
      <form type="input" className="editable">
        {children.substring(0, idx)}
      </form>
    );
  };

  return (
    <div className="responseArea">
      <div className="crudButtonArea">
        <button onClick={save} className="saveButton">
          {" "}
          <FaHeart />{" "}
        </button>
        <button onClick={emailContent} className="saveButton">
          {" "}
          <FaEnvelope />
        </button>
        <button onClick={handleSubmit} className="saveButton createNewButton">
          Create New Ad!
        </button>
        <button onClick={savedAds} className="saveButton createNewButton">
          Go To Saved Ads
        </button>
        {/* <Link onClick={savedAds} className="saveButton createNewButton" to='/register'>Here</Link> */}
      </div>

      <div className="responseBox">
        <Typer speed={speed} children={reply}></Typer>
      </div>

      {/* Conditionally render buttons */}
      {reply && reply.trim().length > 0 && (
        <div>
          <button
            className="saveButton createNewButton bottomButton"
            onClick={reduce}
          >
            Reduce Text
          </button>
          {/* <button className="saveButton createNewButton bottomButton" onClick={postToSocialMedia}>Create Social Media Post</button> */}
          <button
            className="saveButton createNewButton bottomButton"
            onClick={handleEditToggle}
          >
            {isEditing}
          </button>
        </div>
      )}
      <em className="disclosure">
        *Your expertise in real estate is the <b>Key</b> to creating convincing
        text that drives sales. When you create a new ad, your detailed insights
        are crucial for our AI to highlight the home's unique selling points.
        Agents outshine AI in this regard.
      </em>
    </div>
  );
};

export default ApiMessage;
