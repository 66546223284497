import React from 'react';

const LoadingDots= () => {
    return ( 
        <>
        <div className="loading-container">
      <svg className="loading-spinner" viewBox="0 0 50 50">
        
        <circle
          className="loading-spinner-dot"
          cx="45"
          cy="25"
          r="4"
          fill="white"
          strokeWidth="0"
        >
          <animate
            attributeName="cx"
            dur="1.5s"
            repeatCount="indefinite"
            keyTimes="0;0.5;1"
            values="45;5;45"
            begin="0.1"
          />
        </circle>
        <circle
          className="loading-spinner-dot"
          cx="25"
          cy="25"
          r="4"
          fill="white"
          strokeWidth="0"
        >
          <animate
            attributeName="r"
            dur="1.5s"
            repeatCount="indefinite"
            keyTimes="0;0.5;1"
            values="4;0;4"
            begin="0.1"
          />
        </circle>
        <circle
          className="loading-spinner-dot"
          cx="5"
          cy="25"
          r="4"
          fill="white"
          strokeWidth="0"
        >
          <animate
            attributeName="cx"
            dur="1.5s"
            repeatCount="indefinite"
            keyTimes="0;0.5;1"
            values="5;45;5"
            begin="0.1"
          />
        </circle>
      </svg>
    </div>
        </>
     );
}

export default LoadingDots;