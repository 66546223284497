import React, { useEffect, useState } from "react";
import "./Subscription.css";
import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import {
  createCheckoutSession,
  onCurrentUserSubscriptionUpdate,
} from "@invertase/firestore-stripe-payments";
import { getApp } from "@firebase/app";
import { getStripePayments } from "@invertase/firestore-stripe-payments";
import { Link } from "react-router-dom";
import { db, functions } from "../Firebase/firebaseClient";
import { httpsCallable } from "firebase/functions";

const app = getApp();
const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "customers",
});

const auth = getAuth();
getFirestore(app);

const StripeSubscription = () => {
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [subscription, setSubscription] = useState(null); // State to hold user's subscription
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckout = async () => {
    setIsLoading(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const sessionId = await createCheckoutSession(payments, {
          price: "price_1PLfc8GCaIKVMWnzpaQpMBZz",
          success_url:
            "https://www.adtextai.com/register?success=true&session_id={CHECKOUT_SESSION_ID}",
          cancel_url: "https://www.adtextai.com/register?canceled=true",
        });
        window.location.assign(sessionId.url);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during checkout:", error);
      setMessage("Error during checkout. Please try again.");
    }
  };

  const handleCustomerPortal = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const createPortalLink = httpsCallable(
          functions,
          "ext-firestore-stripe-payments-createPortalLink"
        );
        const {
          data: { url: portalUrl },
        } = await createPortalLink({ userId: user.uid });

        window.location.assign(portalUrl);
      }
    } catch (error) {
      console.error("Error creating portal link:", error);
      setMessage("Error creating portal link. Please try again.");
    }
  };

  onCurrentUserSubscriptionUpdate(payments, (snapshot) => {
    for (const change of snapshot.changes) {
      if (change.type === "added") {
        console.log(
          `New subscription added with ID: ${change.subscription.id}`
        );
      }
    }
  });

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    } else if (query.get("canceled")) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  const ProductDisplay = () => (
    <section className="welcome checkout">
      <div className="product-comparison">
        <h3>Choose the right plan for you</h3>
        <div className="plan-options">
          <div className="plan-option">
            <h4 className="plan-title">AdText-AI Basic</h4>
            <p>Access to AdText-AI Basic Features</p>
            <p>Limited Ad Generations</p>
            <p>Slower Response Times</p>
            <div className="plan-footer">
              <span className="createNewButton">Free Forever</span>
            </div>
          </div>
          <div className="plan-option">
            <h4 className="plan-title">AdText-AI Premium</h4>
            <p>Unlimited Ad Generations</p>
            <p>Edit Your Ads</p>
            <p>Save Your Ads</p>
            <p>Unlock Rewrite, Social Media Feature and More!</p>
            <div className="plan-footer">
              <span className="createNewButton" onClick={handleCheckout}>
                $20/month
              </span>
            </div>
          </div>
        </div>
        <button
          className="upgrade-button"
          onClick={handleCheckout}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Upgrade to Premium Plan"}
        </button>
      </div>
    </section>
  );

  const SuccessDisplay = () => (
    <section>
      <div className="product Box-root">
        <div className="description Box-root">
          <h3>Subscription to AdText-AI Premium successful!</h3>
          
        </div>
      </div>
      <form>
        <button>
          <Link to="/compose-new-ad">Get started and create a new ad!</Link>
        </button>
      </form>
    </section>
  );

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          const userDocRef = doc(db, "customers", currentUser.uid);
          const subscriptionsCollectionRef = collection(
            userDocRef,
            "subscriptions"
          );
          const activeSubscriptionsQuery = query(
            subscriptionsCollectionRef,
            where("status", "in", ["active", "trialing"])
          );
          const activeSubscriptionsSnapshot = await getDocs(
            activeSubscriptionsQuery
          );

          if (!activeSubscriptionsSnapshot.empty) {
            setSubscription(true);
            setMessage();
          } else {
            setSubscription(false);
          }
        }
      } catch (error) {
        console.error("Error fetching subscription:", error);
      }
    };

    fetchSubscription();
  }, []);

  const Message = ({ message }) => (
    <section>

      <p>{message}</p>
      <button className="createNewButton" onClick={handleCustomerPortal}>
            Manage Subscription
          </button>
    </section>
  );

  // const UnsubscribeDisplay = () => (
  //   <>
  //     <br />
  //     <form>
  //       <button>
  //         To unsubscribe from AdText-AI Premium
  //         <br />
  //         <a
  //           className="createNewButton"
  //           href="https://billing.stripe.com/p/login/9AQaEI9FJd6telO8ww"
  //           alt="linktostripe"
  //         >
  //           Click Here
  //         </a>
  //       </button>
  //     </form>

  //     {/* <p>*Please note that an update to this feature is comming soon</p> */}
  //     <br />
  //   </>
  // );

  if (!success && message === "") {
    return (
      <>
        {" "}
        <ProductDisplay />
      </>
    );
  } else if (success && sessionId !== "") {
    return (
      <>
        <SuccessDisplay />
      </>
    );
  } else {
    return <Message message={message} />;
  }
};

export default StripeSubscription;
