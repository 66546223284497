import React from "react";

const NavBarLandingPage = ({SignInWithGoogle}) => {
  
  return (
    <>
      <nav className="navBar landingPageNavBar">
        <ul>
          <li>
            <a href="/how-it-works">How It works</a>
          </li>
          {/* <li>
            <a href="/pricing-plans">Pricing</a>
          </li> */}
          <li>
            <a href="/about-us">About Us</a>
          </li>
          <li>
            <a href="/">Home</a>
          </li>
        </ul>
        <div >
            <div className=" userProfile " >
             
             <a href="/user-login-signup-page"><button className="createNewButton" onClick={SignInWithGoogle}>
                TRY FOR FREE
              </button></a> 
            </div>
          </div>
      </nav>
    </>
  );
};

export default NavBarLandingPage;
