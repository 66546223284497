import React, { useState, useEffect } from 'react';
import LoadingDots from './LoadingDots';

const RandomSalesQuote = ({ loadingData }) => {
  const [quote, setQuote] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  // Array of random inspirational sales quotes
  const quotes = [
    "The harder you work for something, the greater you'll feel when you achieve it.",
    "Success is not final, failure is not fatal: It is the courage to continue that counts.",
    "The best way to predict the future is to create it.",
    "Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle.",
    "The secret of getting ahead is getting started.",
    "You don't have to be great to start, but you have to start to be great.",
    "Don't watch the clock; do what it does. Keep going.",
    "Success is walking from failure to failure with no loss of enthusiasm.",
    "The only limit to our realization of tomorrow will be our doubts of today.",
    "The road to success and the road to failure are almost exactly the same.",
  ];

  useEffect(() => {
    const fetchRandomQuote = () => {
      setIsLoading(false);
      const randomIndex = Math.floor(Math.random() * quotes.length);
      // Simulating API call delay
      setTimeout(() => {
        setQuote(quotes[randomIndex]);
        setIsLoading(false);
      }, 15);
    };
    if (isLoading === true) {
      fetchRandomQuote();
    }
  },);

  return (
    <div>
    <p className='loadingQuote'>"{quote}"</p>
    <LoadingDots/>
  </div>
  
  
  );
};

export default RandomSalesQuote;
