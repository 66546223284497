import React, { useState } from "react";
import { FaArrowCircleDown } from "react-icons/fa";
import { Link } from "react-router-dom";
// import { FaChartBar } from "react-icons/fa";

const SideBar = ({ linkTitle, url }) => {
  const [slideBar, setSlideBar] = useState("close");
  const [sideBarUl, setSideBarUl] = useState("ulClose");
  const [sideBarButton, setSideBarButton] = useState("sideBarButton");

  const slideOut = () => {
    setSlideBar(slideBar === "open" ? "close" : "open");

    setSideBarUl(sideBarUl === "sideBarUl" ? "ulClose" : "sideBarUl");

    setSideBarButton(sideBarButton === "sideBarButton" ? "buttonUp" : "sideBarButton");
  };

  return (
    <>
      <div className={`sideBar ${slideBar}`}>
        <div>
          <button onClick={slideOut}><FaArrowCircleDown size="30px" className={`sideBarButton ${sideBarButton}` }/></button>
        </div>

        <ul className={`sideBarUl ${sideBarUl}`}>
        <Link to="/dashboard" className="sideBarHover">Dashboard</Link>
      
          <Link to="/user-account" className="sideBarHover">Account</Link>
          {/* <Link to="/user-settings">Settings</Link> */}
          <Link to={url} className="sideBarHover">{linkTitle}</Link>
          <br />
          <Link to="/reduce-text" className="sideBarHover">Reduce Text</Link>
          <Link to="/rewrite" className="sideBarHover">Rewrite Ad</Link>
          <Link to="/social-media" className="sideBarHover">Social Media</Link>
          <br />
          <Link to="/register" className="upgradeBtnSidebar">Upgrade To Premium!</Link>
        </ul>
      </div>
    </>
  );
};

export default SideBar;
