import React from "react";
import { Link } from "react-router-dom";
// import Logo from '../logo1.JPG';

const NotPremium = () => {
  return (
    <>
      <section>
        <div className="responseBox">
          <img
            src="https://senictv.com/wp-content/uploads/2021/05/pngss-09.png"
            width={200}
            alt="AdText Pro Subscription"
          />
          <p>
            This AdText-AI feature is currently only available to premium
            subscribers.
            <br /> <br />
            To activate this feature, simply upgrade your subscription
            <Link className="createNewButton" to="/register">
              Here
            </Link>
            to get started.
          </p>
        </div>
      </section>
    </>
  );
};

export default NotPremium;
