import React from 'react';

const PricingPage= () => {
    return ( 
        <div >
        {/* <h1 className="welcome pricing ">Pricing</h1> */}
    <div className="pricing-container">
      <div className="pricing-card">
        <h3 className="pricing-card-header">Full Access Plan</h3>
        <p className="pricing-card-price">$7.99<span>/month</span></p>
        <ul className="pricing-card-features">
          <li>Full Access</li>
          <li>1GB Storage</li>
          <li>Full Customer Support</li>
          T's & C's Apply
        </ul>
        <button className="pricing-card-button">Learn More</button>
      </div>
    </div>
    <em>*Subcriptions can be cancelled at any time by contacting our support team</em>
        </div>
     );
}

export default PricingPage;