import React from "react";
import { FaFacebook, FaInstagram,  FaLinkedin } from "react-icons/fa";

const Socials = () => {
  return (
    <div className="socialFooter">
<div className="social-media-icons">
      <a href="https://www.facebook.com/adtextai" target="_blank" rel="noopener noreferrer">
        <FaFacebook />
      </a>
      {/* <a href="https://twitter.com/your-page-url" target="_blank" rel="noopener noreferrer">
        <FaTwitter />
      </a> */}
      <a href="https://www.instagram.com/adtextai/" target="_blank" rel="noopener noreferrer">
        <FaInstagram />
      </a>
      {/* <a href="https://www.youtube.com/your-page-url" target="_blank" rel="noopener noreferrer">
        <FaYoutube />
      </a> */}
      <a href="https://www.linkedin.com/company/adtext" target="_blank" rel="noopener noreferrer">
        <FaLinkedin />
      </a>
    </div>
    </div>
    
  );
};

export default Socials;
