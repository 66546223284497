import React, { useEffect, useRef } from "react";

const HowItWorks = ({SignInWithGoogle}) => {
  const fadeInRefs = useRef([]);

  useEffect(() => {
    const fadeInElements = fadeInRefs.current;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, 
    };

    const fadeInObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-in");
        }
      });
    }, options);

    fadeInElements.forEach((element) => {
      fadeInObserver.observe(element);
    });

    return () => {
      fadeInObserver.disconnect();
    };
  }, []);

  return (
    <>
      <div className="wavy-border">
        <svg viewBox="0 0 100 100" preserveAspectRatio="none">
          <path
            d="M0 50 Q 25 75, 50 50 T 100 50 L 100 100 L 0 100 Z"
            fill="#1d1e26"
          />
        </svg>
      </div>

      <div className="howItWorks">
        <h1 className="mainHeader ">How It Works</h1>
        <p className="copy">
          Our platform provides a seamless experience for creating and managing
          property advertisements, social media posts and much more. Follow
          these simple steps to get started:
        </p>
        <br />
        <div ref={(el) => (fadeInRefs.current[0] = el)} className="content">
          <h2>1. Sign in with Google</h2>
          <p>
            Click on the "Login with Google" button to sign in to our platform
            using your Google account. This will grant you access to our
            powerful features and personalized experience.
          </p>
        </div>

        <div ref={(el) => (fadeInRefs.current[1] = el)} className="content">
          <h2>2. Compose New Ad</h2>
          <p>
            Once you're signed in, you can create a new property advertisement
            by filling out the required information. In the "Compose New Ad"
            section, you can write all the key features of the property, specify
            the suburb, select the target audience (family/investor/luxury), and
            choose the ad size (1/4 page, 1/2 page, or full page). Click on the
            "Create New Ad" button to generate the ad content.
          </p>
        </div>

        <div ref={(el) => (fadeInRefs.current[2] = el)} className="content">
          <h2>3. Generating Ad Content</h2>
          <p>
            Our advanced AI algorithms will generate compelling ad content based
            on the information you provided. While the content is being
            generated, you'll see a loading animation. Sit back and relax while
            our system works its magic!
          </p>
        </div>

        <div ref={(el) => (fadeInRefs.current[3] = el)} className="content">
          <h2>4. Review and Save</h2>
          <p>
            Once the ad content is ready, it will be displayed in the "Ad
            Preview" section. You can review the generated content and make any
            necessary adjustments. If you're satisfied with the result, you can
            save the content to your account by clicking the "Save" button. This
            allows you to access your favorite ad content later.
          </p>
        </div>

        <div ref={(el) => (fadeInRefs.current[4] = el)} className="content">
          <h2>5. Explore Saved Content</h2>
          <p>
            You can view and manage your saved ad content in your user profile.
            Simply click on your profile name in the top banner to access your
            profile. From there, you can browse your saved content, edit or
            delete items as needed.
          </p>
        </div>

        <br className="white-line" />
        <div ref={(el) => (fadeInRefs.current[5] = el)} className="copy">
          <p>
            That's it! You're now ready to create stunning property
            advertisements with ease. If you have any questions or need
            assistance, feel free to reach out to our support team. Happy
            advertising!
          </p>
          <br />
          <br />
          <br />
        </div>
      </div>
      <div className="wavy-border">
      <svg viewBox="0 0 100 100" preserveAspectRatio="none">
  <path
    d="M0 50 Q 25 25, 50 50 T 100 50 L 100 0 L 0 0 Z"
    fill="#1d1e26"
  />
</svg>

      </div>
    </>
  );
};

export default HowItWorks;
