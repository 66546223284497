import React from "react";
// import AIbot from '../WavyTech.png';
import ChatMessage from "./ChatMessage";
import googleLogo from "../Google__G__logo.svg";
import HowItWorks from "./HowItWorks";
import AboutUs from "./AboutUs";

const LandingPage = ({ responseGoogle, SignInWithGoogle }) => {
  return (
    <>
      <div className="LandingPage">
        <h1 className="mainHeader introHeader landingPageHero">ADTEXT - AI</h1>
        <div className="introHeader">
          <h2 className="landingPageCopy subtitle">
            <ChatMessage
              msg={`AI Marketing Tools for Real Estate Agents and Offices | Streamline Your Business with Our Platform.`}
              speed={25}
            />
          </h2>
        </div>
        {/* <img className='aiBotImage' alt='aibot'/> */}
      </div>
      <div id="signIn">
        <div className="google-btn">
          <button className="google-button" onClick={SignInWithGoogle}>
            <img src={googleLogo} alt="Google" className="button-icon" />
            Login with Google
          </button>
        </div>
      </div>
      <br />
      <br />

      <br />
      <HowItWorks />
      <AboutUs />
    </>
  );
};

export default LandingPage;
