import React, { useEffect, useState } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import SideBar from './SideBar';


const NavBar = () => {
  const location = useLocation(); // Get the current location
  const [state, setState] = useState({
    'compose-new-ad': '',
    'agent-bio': '',
    'reduce-text': '',
    'rewrite': '',
    'social-media': ''
  });

  useEffect(() => {
    // Retrieve the state from local storage when the component mounts
    const savedState = localStorage.getItem('CLASS_NAME_STATE');
    if (savedState) {
      setState(JSON.parse(savedState));
    }
  }, []);

  useEffect(() => {
    // Update the active link state based on the current location
    const newState = { ...state };
    for (let key in newState) {
      newState[key] = location.pathname === `/${key}` ? 'active' : '';
    }
    setState(newState);
    // eslint-disable-next-line
  }, [location.pathname]);
   // Update only when the location.pathname changes

  const handleClick = (name) => {
    let newState = { ...state };
    for (let key in newState) {
      if (key === name) {
        newState[key] = 'active';
      } else {
        newState[key] = '';
      }
    }
    // Save the state to local storage
    localStorage.setItem('CLASS_NAME_STATE', JSON.stringify(newState));
    setState(newState);
  };

  return (
    <>
    <SideBar linkTitle={"Saved Ads"} url={"/user-SavedContent"}/>
      <nav className='navBar'>
        <ul>
          <li onClick={() => handleClick('compose-new-ad')} className={state['compose-new-ad']}>
            <Link to='/compose-new-ad'>Compose New Ad</Link>
          </li>
          <li onClick={() => handleClick('agent-bio')} className={state['agent-bio']}>
            <Link to='/agent-bio'>Agent Bio</Link>
          </li>
          <li onClick={() => handleClick('reduce-text')} className={state['reduce-text']}>
            <Link to='/reduce-text'>Reduce Text</Link>
          </li>
          <li onClick={() => handleClick('rewrite')} className={state['rewrite']}>
            <Link to='/rewrite'>Re-write</Link>
          </li>
          {/* <li onClick={() => handleClick('chat')} className={state['chat']}>
            <Link to='/chat'>Chat</Link>
          </li> */}
          <li onClick={() => handleClick('social-media')} className={state['social-media']}>
            <Link to='/social-media'>Social Media</Link>
          </li>
        </ul>
      </nav>
      <Outlet />
      
    </>
  );
};

export default NavBar;
