import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import NavBar from "./NavBar";
import ComposeNewAd from "./ComposeAdCopy";
import ComposeAgentBio from "./AgentBio";
import ReduceText from "./ReduceText";
import ReWrite from "./ReWrite";
import Dashboard from "./Dashboard";
import SavedContent from "./SavedContent";
import UserProfilePage from './UserProfile';
import NotPremium from './NotPremium';
import SocialMedia from './SocialMedia';
import RegisterPage from './RegisterPage';
import { auth, db } from '../Firebase/firebaseClient';
import { doc, collection, query, where, getDocs } from 'firebase/firestore';
import TermsAndConditions from './terms ans service';
import AboutUs from './AboutUs';
import HowItWorks from './HowItWorks';
import TipsForAgents from './Blog Pages/TipsForAgents';


const AllRoutes = ({ user, favs, userDoc }) => {
    const [subscription, setSubscription] = useState(null);

    useEffect(() => {
        const fetchSubscription = async () => {
            try {
                const currentUser = auth.currentUser;
                if (currentUser) {
                    const userDocRef = doc(db, 'customers', currentUser.uid);
                    const subscriptionsCollectionRef = collection(userDocRef, 'subscriptions');
                    const activeSubscriptionsQuery = query(subscriptionsCollectionRef, where('status', 'in', ['active', 'trialing']));
                    const activeSubscriptionsSnapshot = await getDocs(activeSubscriptionsQuery);

                    if (!activeSubscriptionsSnapshot.empty) {
                        setSubscription(true);
                    } else {
                        setSubscription(false);
                    }
                }
            } catch (error) {
                console.error('Error fetching subscription:', error);
            }
        };

        fetchSubscription();
    }, []);

    if (subscription === null) {
        return <div>Loading...</div>;
    }

    return (
        <BrowserRouter>
            <div>
                <main>
                    <Routes>
                        <Route path="/" element={<Navigate to="/dashboard" />} />
                        <Route path="/dashboard" element={<Dashboard user={user} />} />
                        <Route path="/register" element={<><NavBar /><RegisterPage /></>} />
                        <Route path="compose-new-ad" element={<><NavBar /><ComposeNewAd /></>} />
                        <Route path="/user-account" element={<><UserProfilePage userDoc={userDoc} /></>} />
                        <Route path="/terms-and-conditions" element={ <><NavBar/><TermsAndConditions/></> } />
                        <Route path="/about-us" element={<><NavBar /><AboutUs /></>} />
                        <Route path="/how-it-works" element={<><NavBar /><HowItWorks /></>} />
                        <Route path="/user-login-signup-page" element={<><NavBar /><Dashboard user={user}/></>} />
                        <Route path="/tips-for-agents" element={<><NavBar /><TipsForAgents/></>} />
                        {subscription ? (
                            <>
                                <Route path="agent-bio" element={<><NavBar /><ComposeAgentBio /></>} />
                                <Route path="reduce-text" element={<><NavBar /><ReduceText /></>} />
                                <Route path="rewrite" element={<><NavBar /><ReWrite /></>} />
                                <Route path="/social-media" element={<><NavBar /><SocialMedia /></>} />
                                <Route path="/user-savedContent" element={<><NavBar /><SavedContent favs={favs} /></>} />
                            </>
                        ) : (
                            <>
                                <Route path="agent-bio" element={<><NavBar /><NotPremium /></>} />
                                <Route path="reduce-text" element={<><NavBar /><NotPremium /></>} />
                                <Route path="rewrite" element={<><NavBar /><NotPremium /></>} />
                                <Route path="/social-media" element={<><NavBar /><NotPremium /></>} />
                                <Route path="/user-savedContent" element={<><NavBar /><NotPremium /></>} />
                            </>
                        )}
                    </Routes>
                </main>
            </div>
        </BrowserRouter>
    );
}

export default AllRoutes;
